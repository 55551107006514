import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  OnDestroy,
  OnInit,
  signal
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {
  ADMIN_QUEST_PUBLISH_REQUESTS_PATH, EXPLORATION_PATH, MESSAGES_PATH, MY_CHAINS_PATH,
  MY_QUESTS_PATH,
  SHARED_QUESTS_PATH
} from '../../app-routing.module';
import {NavItem} from './types';
import {UserService} from '../../services/user.service';
import {Subject, take, takeUntil} from 'rxjs';
import {TranslocoService} from '@ngneat/transloco';
import {ODMService} from '../../services/odm.service';
import {toSignal} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarNavComponent implements OnInit, OnDestroy {
  sidebarItems?: NavItem[] = [
    {title: this.transloco.translate('sideNav.myQuests'), svg: 'map', link: MY_QUESTS_PATH},
    {title: this.transloco.translate('sideNav.sharedWithMe'), svg: 'share', link: SHARED_QUESTS_PATH},
    {title: this.transloco.translate('sideNav.messages'), svg: 'message', link: MESSAGES_PATH},
    {title: this.transloco.translate('sideNav.myChains'), svg: 'link', link: MY_CHAINS_PATH, hidden: () => !this.isSuperUser()},
    {title: this.transloco.translate('sideNav.exploration'), svg: 'compass', link: EXPLORATION_PATH, hidden: () => !this.isSuperUser() && !this.user()?.subscriptionPlan},
    {title: this.transloco.translate('sideNav.questRequests'), svg: 'settings', link: ADMIN_QUEST_PUBLISH_REQUESTS_PATH, hidden: () => !this.isSuperUser()}
  ]
  user = toSignal(this.userService.getUserInfo$());

  private isSuperUser = computed(() => this.user()?.isSuperUser);
  private destroy$ = new Subject<void>();

  constructor(private router: Router,
              private transloco: TranslocoService,
              private userService: UserService,
              private odmService: ODMService,
              private cdRef: ChangeDetectorRef) {
    // this.userService.getUserInfo$().pipe(take(1), takeUntil(this.destroy$)).subscribe((user) => {
    //   this.isSuperUser.set(user.isSuperUser);
    //   this.cdRef.markForCheck();
    // });

    this.odmService.getUnreadODMsCount$().pipe(takeUntil(this.destroy$)).subscribe((count) => {
      this.sidebarItems[2].notificationCount = count;
      this.cdRef.markForCheck();
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidebarItems = this.sidebarItems.map((item) => ({
          ...item,
          active: event.urlAfterRedirects.includes(item.link)
        }));
        this.cdRef.markForCheck();
      }
    });

    this.sidebarItems = this.sidebarItems.map((item) => ({
      ...item,
      active: this.router.url.includes(item.link)
    }));
  }

  navigateToMenuItem(menuItem) {
    this.router.navigate([`/${menuItem.link}`]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
