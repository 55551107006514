// @ts-ignore
import * as mapboxgl from 'mapbox-gl';
import {
  GeoCoords,
  QGraph,
  QGraphConnection,
  QGraphVertex,
  QGraphVertexWithRadius
} from '../interfaces/geo-and-movement.interfaces';
import * as GeoJSON from 'geojson';
import * as turf from '@turf/turf'
import {isStartZoneCircle} from '../interfaces/quest-core.interfaces';
import {nMetersFromCoords} from './geo.functions';
import {Dict} from '../interfaces/common.interfaces';

const ZONE_LINE_WIDTH = 3;

export function addLayersToMap(map: mapboxgl.Map): void {
  map.addSource('start-zones', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  });

  map.addSource('start-zone-outline', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  });

  map.addLayer({
    id: 'start-zones',
    type: 'fill',
    source: 'start-zones',
    layout: {},
    paint: {
      'fill-color': '#34C759',
      'fill-opacity': 0.3
    }
  });

  map.addLayer({
    id: 'start-zone-outline',
    type: 'line',
    source: 'start-zone-outline',
    layout: {},
    paint: {
      'line-color': '#34C759',
      'line-width': ZONE_LINE_WIDTH
    }
  });

  map.addSource('hazard-zones', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  });

  map.addSource('hazard-zones-outline', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  });

  map.addLayer({
    id: 'hazard-zones',
    type: 'fill',
    source: 'hazard-zones',
    layout: {},
    paint: {
      'fill-color': '#FF3B30',
      'fill-opacity': 0.3
    }
  });

  map.addLayer({
    id: 'hazard-zones-outline',
    type: 'line',
    source: 'hazard-zones-outline',
    layout: {},
    paint: {
      'line-color': '#FF3B30',
      'line-width': ZONE_LINE_WIDTH
    }
  });

  map.addSource('task-zone', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  });

  map.addSource('task-zone-outline', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: []
    }
  });

  map.addLayer({
    id: 'task-zone',
    type: 'fill',
    source: 'task-zone',
    layout: {},
    paint: {
      'fill-color': '#F2C94C',
      'fill-opacity': 0.3
    }
  });

  map.addLayer({
    id: 'task-zone-outline',
    type: 'line',
    source: 'task-zone-outline',
    layout: {},
    paint: {
      'line-color': '#F2C94C',
      'line-width': 2
    }
  });
}

export function drawMarkerOnMap(coords: GeoCoords, map: mapboxgl.Map, path = '../../../../assets/map-icons/active-marker-1.svg'): mapboxgl.Marker {
  const el = document.createElement('div');
  el.innerHTML = `<img src="${path}" alt="NO IMG">`
  el.className = 'find-marker';

  return new mapboxgl.Marker(el).setLngLat(coords).addTo(map);
}

export const drawStaticMarker = (
  coords: GeoCoords,
  type: 'revealed' | 'reached' | 'with-text',
  data?: Dict<string | number>,
  onClick?: () => void
): mapboxgl.Marker => {
  let path = '';
  switch (type) {
    case 'revealed':
      path = 'assets/gameplay-icons/revealed-marker.svg';
      break;
    case 'reached':
      path = 'assets/gameplay-icons/reached-marker.svg';
      break;
    case 'with-text':
      path = 'assets/gameplay-icons/revealed-marker-empty.svg';
      break;
    default:
      throw Error('Unknown marker type ' + type);
  }
  const el = document.createElement('div');
  el.innerHTML = `<img src="${path}" alt="NO IMG">`;
  if (type === 'with-text') {
    let cssClass =  data?.['text'] === '!' ? 'exclamation-mark' : 'marker-number'
    if (data?.['text']?.toString().length > 1) {
      cssClass += ' two-digits';
    }
    el.innerHTML += `<span class="${cssClass}">${data?.['text']}</span>`;
  }
  el.className = 'map-marker '  + (type === 'reached' ? 'reached' : '');

  if (onClick) {
    el.style.cursor = 'pointer';
  }

  const marker = new mapboxgl.Marker(el).setLngLat(coords);

  if (onClick) {
    el.addEventListener('click', (ev: any) => {
      onClick();

      ev.stopPropagation();
    });
  }

  return marker;
};

export const drawCommentOnMap = (coords: GeoCoords, map: mapboxgl.Map, active = false): {
  popup: mapboxgl.Popup,
  updateComment: (comment: string, imgUrl?: string, active?: boolean) => void
} => {
  let comment = '';
  let actualComment = '';
  let imageUrl = '';

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false
  })
    .setLngLat([coords.lng, coords.lat])
    .setHTML(`<div class="regular-14 comment-on-map-content"></div>`)
    .addTo(map);

  const updateComment = (c: string, u?: string, a?: boolean) => {
    if (a !== undefined) {
      active = a;
    }

    if (!c) {
      c = '';
    }

    comment = c;
    actualComment = active ? c : (c.length > 20 ? c.slice(0, 20) + '...' : c);
    imageUrl = u;

    popup.setHTML(`
<div class="regular-14 comment-on-map-content ${active ? '' : 'unfocused'}">
${u ? `<img src="${u}" alt="NO IMG">` : ''}
${actualComment}
</div>`);
  }

  popup.getElement().addEventListener('mouseenter', () => {
    active = true;

    updateComment(comment, imageUrl);
  });

  popup.getElement().addEventListener('mouseleave', () => {
    active = false;

    updateComment(comment, imageUrl);
  });

  return {
    popup,
    updateComment
  };
}

export function zoneToFeatureLineString(zone: QGraph | QGraphVertexWithRadius, closeZone = false): GeoJSON.Feature {
  let coordinates: GeoJSON.Position[] = []

  if (isStartZoneCircle(zone)) {
    return turf.circle([zone.coords.lng, zone.coords.lat], zone.data.radius, {steps: 60, units: 'meters'});
  } else {
    if (zone.connections?.length) {
      const connection = zone.connections![0];
      const firstVertex = zone.vertices[connection.startVertexId];
      const firstCoords = [firstVertex.coords.lng, firstVertex.coords.lat];

      coordinates = [firstCoords, ...zone.connections!.map(c => {
        const vertex = zone.vertices[c.endVertexId];
        return [vertex.coords.lng, vertex.coords.lat];
      })];

      if (closeZone) {
        coordinates.push(firstCoords);
      }
    }

    return {
      type: 'Feature',
      properties: null,
      geometry: {
        type: 'LineString',
        coordinates
      }
    } as GeoJSON.Feature;
  }
  //not sure if I want to use turf here an obfuscate GeoJSON
  // turf.lineString([firstCoords, ...zone.connections!.map(c => {
  //   const vertex = zone.vertices[c.endVertexId];
  //   return [vertex.coords.lng, vertex.coords.lat];
  // })])
}

export function zoneToFeaturePolygon(zone: QGraph | QGraphVertexWithRadius): GeoJSON.Feature {
  let coordinates: GeoJSON.Position[][] = []

  if (isStartZoneCircle(zone)) {
    return turf.circle([zone.coords.lng, zone.coords.lat], zone.data.radius, {steps: 60, units: 'meters'});
  }

  if (zone.connections?.length && zone.connections?.length >= 2) {
    const connection = zone.connections![0];
    const firstVertex = zone.vertices[connection.startVertexId];
    const firstCoords = [firstVertex.coords.lng, firstVertex.coords.lat];

    coordinates = [[firstCoords, ...zone.connections!.map(c => {
      const vertex = zone.vertices[c.endVertexId];
      return [vertex.coords.lng, vertex.coords.lat];
    })]];
  }

  return {
    type: 'Feature',
    properties: null,
    geometry: {
      type: 'Polygon',
      coordinates
    }
  } as GeoJSON.Feature;
}

export function getAllCoordinatesFromZone(zone: QGraph | QGraphVertexWithRadius): GeoCoords[] {
  let coords: GeoCoords[] = [];

  if (isStartZoneCircle(zone)) {
    coords = [
      nMetersFromCoords(zone.coords, zone.data.radius, 'n'),
      nMetersFromCoords(zone.coords, zone.data.radius, 'e'),
      nMetersFromCoords(zone.coords, zone.data.radius, 's'),
      nMetersFromCoords(zone.coords, zone.data.radius, 'w')
    ]
  } else {
    for (const [, value] of Object.entries(zone.vertices)) {
      coords.push(value.coords);
    }
  }

  return coords;
}

export function coordsToQGraph(coords: GeoCoords[]): QGraph {
  const vertices: Dict<QGraphVertex> = {};
  const connections: Array<QGraphConnection> = [];

  coords.forEach((c, index) => {
    vertices[index] = {
      id: `${index}`,
      coords: c
    }

    if (index > 0) {
      connections.push({
        startVertexId: `${index - 1}`,
        endVertexId: `${index}`
      })
    }
  });

  return {
    vertices,
    connections
  }
}

export const adjustMapBBox = (coords: GeoCoords[], map: mapboxgl.Map, bottomOffset = 0, leftOffset = 0, padding = 70): void => {
  if (coords.length > 1) {
    const mapHeight = map.getContainer().clientHeight;

    const percentCoveredByModal = bottomOffset / mapHeight;

    const mapWidth = map.getContainer().clientWidth;

    const percentCoveredByLeftOffset = leftOffset / mapWidth;

    const coordinates: [number, number][] = coords.map(v => [v.lng, v.lat]);

    const bounds = new mapboxgl.LngLatBounds(
      coordinates[0],
      coordinates[0]
    );

    for (const coord of coordinates) {
      bounds.extend(coord);
    }

    const distY = bounds.getNorth() - bounds.getSouth();

    // we compensate for the bottom modal height
    bounds.extend([bounds.getEast(), bounds.getSouth() - distY * percentCoveredByModal]);

    const distX = bounds.getEast() - bounds.getWest();

    // we compensate for the left panel height
    bounds.extend([bounds.getWest() - distX * percentCoveredByLeftOffset, bounds.getNorth()]);

    map.fitBounds(bounds, {
      padding
    });
  } else {
    map.setCenter([coords[0].lng, coords[0].lat]);
  }
};

export const getDistanceInPixels = (map: mapboxgl.Map, point1: GeoCoords, point2: GeoCoords): number => {
  // Project geographical coordinates to pixel coordinates
  const pixelCoords1 = map.project(point1);
  const pixelCoords2 = map.project(point2);

  // Calculate the Euclidean distance between the two pixel coordinates
  const dx = pixelCoords2.x - pixelCoords1.x;
  const dy = pixelCoords2.y - pixelCoords1.y;
  return Math.sqrt(dx * dx + dy * dy);
}
