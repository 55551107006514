import {GeoCoords, QGraph} from './geo-and-movement.interfaces';
import {Dict} from './common.interfaces';
import {ObeliskImportance, ObeliskType} from './obelisks.enums';
import {QuestUser} from './social.interfaces';

export interface Obelisk {
  uuid?: string;
  name: string;
  subTitle?: string;
  creator?: QuestUser;
  description?: string;
  location?: GeoCoords;
  svg?: string;
  type: ObeliskType;
  status?: 'place' | 'marker' | 'hidden';
  category?: string;
  importance?: ObeliskImportance | string;
  rewardsExp?: number;
  address?: string;
  approved?: boolean;
  zone?: ObeliskZone;
  config?: Dict<any>;
  createdAt?: Date;
  updatedAt?: Date;
  images?: ObeliskImage[];

  createdAgo?: string;
  updatedAgo?: string;
}

export interface Marker {
  uuid?: string;
  name: string;
  creator?: QuestUser;
  description?: string;
  location: GeoCoords;
  status?: 'marker';
  address?: string;
  config?: Dict<any>;
  createdAt?: Date;
  updatedAt?: Date;
  additionalData?: Dict<any>;
  distance?: number;
  images?: ObeliskImage[];
}

export interface ObeliskImage {
  uuid: string;
  uri?: string;
  saved?: boolean;
  order?: number;
}

export interface ObeliskZoneCircle {
  type: 'circle';
  radius: number; // in meters
}

export interface RelativeCoords {
  lngDiff: number;
  latDiff: number;
}

export interface ObeliskZonePolygon {
  type: 'polygon';
  vertices: RelativeCoords[];
  absolutePolygon?: QGraph;
}

export interface Pathway {
  uuid?: string;
  name: string;
  color: string;
  vertices: GeoCoords[];
  path?: QGraph;
  mappedToRoads?: boolean;
  mapMappedPath?: GeoJSON.LineString;
  mappedPathProfile?: 'walking' | 'cycling' | 'driving';
}

export interface ObeliskComment {
  uuid?: string;
  coords: GeoCoords,
  text: string,
  imageUrl?: string
}

export type ObeliskZone = ObeliskZoneCircle | ObeliskZonePolygon;

export const isObeliskZoneCircle = (zone: ObeliskZone): zone is ObeliskZoneCircle => zone.type === 'circle';
