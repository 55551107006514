<div class="comment-controls" [formGroup]="commentFormGroup()">
  <mat-form-field appearance="fill" class="form-field mat-text-area text-area" color="accent">
    <mat-label>{{'comment' | transloco}}</mat-label>
    <textarea matInput formControlName="text" rows="5" maxlength="700"></textarea>
  </mat-form-field>
  @if (commentFormGroup().value.imageUrl) {
    <div class="img-thumbnail image-area">
      <img [src]="commentFormGroup().value.imageUrl" alt="" />
      <div class="close-icon-container">
        <svg class="close-icon pointer" viewBox="0 0 24 24" (click)="commentFormGroup().patchValue({imageUrl: undefined})">
          <use xlink:href="assets/project-icons/close.svg#c"></use>
        </svg>
      </div>
    </div>
  } @else {
    <div class="photo-area" (click)="addPhoto()">
      {{'addImage' | transloco}}
    </div>
  }
  <div class="controls">
    <button class="image-btn">
      <q-svg-wrapper iconPath="project-icons/x" (click)="delete()"></q-svg-wrapper>
    </button>
    <button class="image-btn" [ngClass]="{active: editEngine().editing}" (click)="editEngine().editing ? editEngine().stopEditing() : editEngine().startEditing()">
      <q-svg-wrapper iconPath="project-icons/location"></q-svg-wrapper>
    </button>
  </div>
</div>

<input [id]="imageInputId()" style="display: none" type="file" accept="image/png, image/jpeg" />
