import * as mapboxgl from 'mapbox-gl';
import {QGraph, GenericZoneEditEngine} from 'quest-atlas-angular-components';
import {Observable, of} from 'rxjs';

export class ObeliskZoneEditEngine extends GenericZoneEditEngine {
  constructor(map: mapboxgl.Map,
              zone?: QGraph,
              callbackOnAnyClick?: (zoneEngine: GenericZoneEditEngine) => void) {
    super(map, null,'start-zones', 'start-zone-outline', false, null, zone, callbackOnAnyClick);
  }

  getStrokeColor$(): Observable<string> {
    return of('#34C759');
  }

}
