import {MapControlPanelRequestBody, MapEditEngine} from './map-edit-engine.interface';
import {Observable, Subject} from 'rxjs';
import {GeoCoords} from '../../interfaces/geo-and-movement.interfaces';
import * as mapboxgl from 'mapbox-gl';
import {drawCommentOnMap} from '../../functions/map-general.functions';

export class CommentsEditEngine implements MapEditEngine {
  editing: boolean;

  private popup?: mapboxgl.Popup;
  private updateCommentFunc: (comment: string, imageUrl?: string, active?: boolean) => void;
  private edit$ = new Subject<void>();
  private startEditSubj$ = new Subject<void>();
  private stopEditSubj$ = new Subject<void>();

  constructor(private map: mapboxgl.Map, private uuid?: string, private coords?: GeoCoords, private comment?: string, private imageUrl?: string) {
    if (coords) {
      const {popup, updateComment} = drawCommentOnMap(coords, this.map, true);

      this.popup = popup;
      this.updateCommentFunc = updateComment;

      updateComment(this.comment, this.imageUrl, false);
    }
  }

  postInit(): void {
  }

  clear(): void {
    if (this.popup) {
      this.popup.remove();
    }

    this.updateCommentFunc = undefined;
  }

  clickOnMap(clickCoords: GeoCoords): void {
    if (!this.editing) {
      return;
    }

    if (this.popup) {
      this.popup.remove();
    }
    this.coords = clickCoords;

    const {popup, updateComment} = drawCommentOnMap(clickCoords, this.map, true);

    this.popup = popup;
    this.updateCommentFunc = updateComment;

    updateComment(this.comment);

    this.edit$.next();
  }

  hoverOnMap(coords: GeoCoords) {}

  externalRedraw(): void {
  }

  getData(): any {
    return {
      uuid: this.uuid,
      coords: this.coords,
      updateCommentFunc: this.updateCommentFunc
    }
  }

  isValid(): boolean {
    return false;
  }

  mapControlPanelRequest$(): Observable<MapControlPanelRequestBody> {
    return undefined;
  }

  nonEditClickOnMap(clickCoords: GeoCoords): void {
  }

  onEdit$(): Observable<void> {
    return this.edit$.asObservable();
  }

  removeSelf$(): Observable<void> {
    return undefined;
  }

  updateComment(comment: string, imageUrl?: string, active?: boolean): void {
    this.comment = comment;
    this.imageUrl = imageUrl;

    if (this.updateCommentFunc) {
      if (active !== undefined) {
        this.updateCommentFunc(comment, imageUrl, active);
      } else {
        this.updateCommentFunc(comment, imageUrl);
      }
    }
  }

  startEditing(): void {
    this.editing = true;
    this.startEditSubj$.next();
    if (this.updateCommentFunc) {
      this.updateCommentFunc(this.comment, this.imageUrl, true);
    }
  }

  onStartEdit$(): Observable<void> {
    return this.startEditSubj$.asObservable();
  }

  stopEdit$(): Observable<void> {
    return this.stopEditSubj$.asObservable();
  }

  stopEditing(): void {
    this.editing = false;
    this.stopEditSubj$.next();
    if (this.updateCommentFunc) {
      this.updateCommentFunc(this.comment, this.imageUrl, false);
    }
  }

  hidePopup(): void {
    if (this.popup) {
      this.popup.getElement().style.display = 'none';
    }
  }

  showPopup(): void {
    if (this.popup) {
      this.popup.getElement().style.display = 'flex';
    }
  }

  destroy(): void {
    this.edit$.complete()
    this.startEditSubj$.complete()
    this.stopEditSubj$.complete()
  }
}
