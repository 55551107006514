import {
  MapControlPanelAction,
  MapControlPanelRequestBody,
  MapEditEngine,
  MapEditEngineInstancesCluster
} from './map-edit-engine.interface';
// @ts-ignore
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
// @ts-ignore
import {v4 as uuidv4} from 'uuid';

import * as GeoJSON from 'geojson';
// import * as turf from '@turf/turf'
import {Observable, Subject, take} from 'rxjs';
import {translateObject} from '@ngneat/transloco';
import {GeoCoords, QGraph, QGraphConnection, QGraphVertex} from '../../interfaces/geo-and-movement.interfaces';
import {checkIfInThePolygon} from '../../functions/geo.functions';
import {
  getDistanceInPixels,
  zoneToFeatureLineString,
  zoneToFeaturePolygon
} from '../../functions/map-general.functions';
import {LineString} from 'geojson';

export interface VertexData {
  marker: mapboxgl.Marker,
  selected: boolean
}

export abstract class GenericZoneEditEngine implements MapEditEngine {
  editing!: boolean;

  zone: QGraph<VertexData>;
  selectedVertex?: QGraphVertex<VertexData>;
  prevSelectedVertex?: QGraphVertex<VertexData>;
  phantomMarker?: mapboxgl.Marker;
  orderedVertices: QGraphVertex<VertexData>[] = [];
  zoneConfirmed = false;
  hoveringOverRealVertex = false;
  mapControlPanelRequestSubject$ = new Subject<MapControlPanelRequestBody>();
  lastStrokeGeoJSONFeatures: GeoJSON.Feature[] = [];
  lastPolygonGeoJSONFeatures: GeoJSON.Feature[] = [];
  zoneName: string = translateObject('zones.empty') as string;

  private dragging = false;

  private removeSelfSubj$ = new Subject<void>();
  private zoneConfirmSubj$ = new Subject<QGraph<VertexData>>();
  private stopEditSubj$ = new Subject<void>();
  private edit$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  protected constructor(protected map: mapboxgl.Map,
                        protected mapControlPanelAction$: Observable<MapControlPanelAction>,
                        protected fillSourceName: string,
                        protected outlineSourceName: string,
                        protected markersDraggable = false,
                        protected cluster?: MapEditEngineInstancesCluster<MapEditEngine>,
                        zone?: QGraph,
                        protected callbackOnAnyClick: (zoneEngine: GenericZoneEditEngine) => void = () => {}) {
    if (zone) {
      [this.zone, this.orderedVertices] = this.toFunctionalZone(zone);
      this.zoneConfirmed = true;

      this.updateZoneName();
      this.map.on('load', () => {
        //need to wait when the data will be initialized
        setTimeout(() => {
          this.updateZoneOutlineOnMap(true);
          this.updateZoneFillOnMap();
        }, 100);
      });
    } else {
      this.zone = {
        vertices: {},
        connections: []
      }
    }

    // mapControlPanelAction$.pipe(filter(() => this.editing)).subscribe(value => {
    //   if (value.button === 'confirm') {
    //     this.updateZoneOutlineOnMap(true);
    //     this.updateZoneFillOnMap();
    //     this.stopEditing();
    //     this.zoneConfirmed = true;
    //     this.updateZoneName();
    //   }
    // })
  }

  postInit(): void {
    this.getStrokeColor$().subscribe(color => {
      this.orderedVertices.forEach(value => {
        if (value.data?.marker) {
          value.data.marker.getElement().getElementsByTagName('svg')[0].style.color = color;
        }
      });
    });
  }

  removeSelf$(): Observable<void> {
    return this.removeSelfSubj$.asObservable();
  }

  mapControlPanelRequest$(): Observable<MapControlPanelRequestBody> {
    return this.mapControlPanelRequestSubject$.asObservable();
  }

  clickOnMap(clickCoords: GeoCoords): void {
    if (!this.editing) {
      return;
    }

    if (this.selectedVertex) {
      if (this.selectedVertex !== this.orderedVertices.at(-1) && !this.phantomMarker) {
        this.unselectCurrentVertex();
        this.selectVertex(this.orderedVertices.at(-1)!);
      }

      this.unselectCurrentVertex();
    }

    if (this.phantomMarker) {
      clickCoords = this.phantomMarker.getLngLat();
    }

    let connectionClicked: QGraphConnection;

    if (this.zone.connections && this.phantomMarker) {
      let closestDistance = 20000;

      for (const connection of this.zone.connections!) {
        const startVertex = this.zone.vertices[connection.startVertexId];
        const endVertex = this.zone.vertices[connection.endVertexId];

        const lineString = turf.lineString([[startVertex.coords.lng, startVertex.coords.lat], [endVertex.coords.lng, endVertex.coords.lat]]);
        const point = turf.point([clickCoords.lng, clickCoords.lat]);

        if (turf.pointToLineDistance(point, lineString) < closestDistance) {
          closestDistance = turf.pointToLineDistance(point, lineString);
          connectionClicked = connection;
        }
      }
    }

    this.selectedVertex = {
      id: uuidv4(),
      coords: clickCoords,
      data: {marker: null, selected: true}
    }

    this.zone.vertices[this.selectedVertex.id!] = this.selectedVertex;

    if (!connectionClicked) {
      this.orderedVertices.push(this.selectedVertex);

      if (this.prevSelectedVertex) {
        this.zone.connections?.push({
          startVertexId: this.prevSelectedVertex.id!,
          endVertexId: this.selectedVertex!.id!
        });

        this.updateZoneOutlineOnMap();
      }
    } else {
      const scStartVertex = this.zone.vertices[connectionClicked.startVertexId];
      const scEndVertex = this.zone.vertices[connectionClicked.endVertexId];

      const indexOfConnectionClicked = this.zone.connections.indexOf(connectionClicked);

      const newConnection1: QGraphConnection = {
        startVertexId: scStartVertex.id!,
        endVertexId: this.selectedVertex.id!
      }

      const newConnection2: QGraphConnection = {
        startVertexId: this.selectedVertex.id!,
        endVertexId: scEndVertex.id!
      }

      this.zone.connections.splice(indexOfConnectionClicked, 1, newConnection1, newConnection2);
      this.orderedVertices.splice(this.orderedVertices.indexOf(scEndVertex), 0, this.selectedVertex);

      this.hoveringOverRealVertex = true;
    }

    this.getStrokeColor$().pipe(take(1)).subscribe(color => {
      this.selectedVertex!.data!.marker = this.drawVertexMarker(clickCoords, this.selectedVertex, color, this.markersDraggable);
    });

    this.onZoneChange();

    this.updateZoneName();

    if (this.phantomMarker) {
      this.phantomMarker.remove();
      this.phantomMarker = undefined;
    }

    this.edit$.next();
  }

  hoverOnMap(coords: GeoCoords) {
    if (this.dragging) {
      if (this.phantomMarker) {
        this.phantomMarker.remove();
        this.phantomMarker = undefined;
      }

      return;
    }

    if (!this.editing) {
      return;
    }

    if (!this.zone.connections || this.zone.connections.length < 2) {
      return;
    }

    if (this.hoveringOverRealVertex) {
      if (this.phantomMarker) {
        this.phantomMarker.remove();
        this.phantomMarker = undefined;
      }

      return;
    }

    const zoneAsLineString = zoneToFeatureLineString(this.zone);

    const pointOnLine = turf.nearestPointOnLine(zoneAsLineString.geometry as LineString, turf.point([coords.lng, coords.lat]));

    const pxToLine = getDistanceInPixels(this.map, coords, {lng: pointOnLine.geometry.coordinates[0], lat: pointOnLine.geometry.coordinates[1]});

    if (pxToLine > 27) {
      if (this.phantomMarker) {
        this.phantomMarker.remove();
        this.phantomMarker = undefined;
      }

      return;
    }

    for (const vertex of this.orderedVertices) {
      const pxToVertex = getDistanceInPixels(this.map, coords, vertex.coords);

      if (pxToVertex < 20) {
        if (this.phantomMarker) {
          this.phantomMarker.remove();
          this.phantomMarker = undefined;
        }

        return;
      }
    }

    if (this.phantomMarker) {
      this.phantomMarker.setLngLat({lng: pointOnLine.geometry.coordinates[0], lat: pointOnLine.geometry.coordinates[1]});
    } else {
      this.getStrokeColor$().pipe(take(1)).subscribe(color => {
        this.phantomMarker = this.drawPhantomMarker({lng: pointOnLine.geometry.coordinates[0], lat: pointOnLine.geometry.coordinates[1]}, color);
      });
    }
  }

  nonEditClickOnMap(clickCoords: GeoCoords): void {
    if (this.zoneConfirmed
      && this.zone.connections
      && this.zone.connections.length > 1
      && checkIfInThePolygon(clickCoords,
        [this.zone.vertices[this.zone.connections[0].startVertexId].coords,
          ...this.zone.connections.map(value => this.zone.vertices[value.endVertexId].coords)])) {

      this.callbackOnAnyClick(this);
    }
  }

  startEditing(): void {
    this.editing = true;
    this.zoneConfirmed = false;

    if (this.orderedVertices.length) {
      this.selectVertex(this.orderedVertices.at(-1)!);
    }
    this.updateZoneOutlineOnMap(false);
    this.updateZoneFillOnMap(true);

    this.onZoneChange();
    this.updateZoneName();
  }

  stopEditing(): void {
    this.unselectCurrentVertex();

    this.editing = false;
    this.stopEditSubj$.next();

    // this.mapControlPanelRequestSubject$.next({
    //   state: 'hide',
    //   showConfirm: false
    // });
  }

  hide(): void {
    this.updateZoneOutlineOnMap(true);
    this.updateZoneFillOnMap(true);

    this.hideMarkers();
  }

  hideMarkers(): void {
    for (const [, val] of Object.entries(this.zone.vertices)) {
      if (val.data?.marker) {
        val.data.marker.getElement().style.display = 'none';
      }
    }
  }

  reveal(): void {
    this.updateZoneOutlineOnMap();
    this.updateZoneFillOnMap();

    this.revealMarkers();
  }

  revealMarkers(): void {
    for (const [, val] of Object.entries(this.zone.vertices)) {
      if (val.data?.marker) {
        val.data.marker.getElement().style.display = 'block';
      } else {
        this.getStrokeColor$().pipe(take(1)).subscribe(color => {
          val.data!.marker = this.drawVertexMarker(val.coords, val, color, this.markersDraggable);
        });
      }
    }
  }

  clear(): void {
    for (const [, val] of Object.entries(this.zone.vertices)) {
      val.data?.marker?.remove();
    }

    this.zone = {
      vertices: {},
      connections: []
    };
    this.orderedVertices = [];
    this.prevSelectedVertex = undefined;
    this.selectedVertex = undefined;

    this.updateZoneOutlineOnMap();
    this.updateZoneFillOnMap(true);
    this.onZoneChange();
    this.updateZoneName();

    this.edit$.next();
  }

  getData(): any {
    const cleanZone: QGraph = this.toCleanZone();

    return {
      empty: Object.keys(this.zone.vertices).length === 0,
      fullZone: this.zone,
      lastStrokeGeoJSONFeatures: this.lastStrokeGeoJSONFeatures,
      lastPolygonGeoJSONFeatures: this.lastPolygonGeoJSONFeatures,
      cleanZone,
      vertices: this.orderedVertices.map(value => value.coords),
    }
  }

  isValid(): boolean {
    return Object.keys(this.zone.vertices).length === 0 || (this.zone.connections!.length >= 2 && this.zoneConfirmed);
  }

  externalRedraw(): void {
    this.updateZoneOutlineOnMap(this.zoneConfirmed);
    this.updateZoneFillOnMap(this.zone.connections?.length < 3 || this.editing);
  }

  onZoneConfirmed$(): Observable<QGraph<VertexData>> {
    return this.zoneConfirmSubj$.asObservable();
  }

  setZone(zone: QGraph): void {
    this.clear();

    [this.zone, this.orderedVertices] = this.toFunctionalZone(zone);
    this.updateZoneName();
    this.updateZoneOutlineOnMap(true);
    this.updateZoneFillOnMap();
  }

  onEdit$(): Observable<void> {
    return this.edit$.asObservable();
  }

  abstract getStrokeColor$(): Observable<string>;

  protected drawVertexMarker(coords: GeoCoords, thisVertex?: QGraphVertex<VertexData>, strokeColor?: string, draggable?: boolean): mapboxgl.Marker {
    const el = document.createElement('div');
    el.className = 'pointer';
    el.style.width = '16px';
    el.style.height = '16px';
    el.innerHTML=`<svg width="16" height="16" style="color: ${strokeColor}">
                    <use xlink:href="../../../../assets/map-icons/zone-vertex.svg#c"></use>
                  </svg>`
    if (thisVertex?.data?.selected) {
      el.appendChild(this.createVertexControlModal());
    }

    const marker = new mapboxgl.Marker({element: el, draggable}).setLngLat(coords).addTo(this.map);
    marker.getElement().addEventListener('click', (ev: any) => {
      if (this.editing && thisVertex !== this.selectedVertex) {
        if (this.selectedVertex) {
          this.unselectCurrentVertex()
        }

        this.selectVertex(thisVertex!);
      }
      if (this.callbackOnAnyClick) {
        this.callbackOnAnyClick(this);
      }

      ev.stopPropagation();
    });

    marker.getElement().addEventListener('mouseenter', () => {
      this.hoveringOverRealVertex = true;
      if (this.phantomMarker) {
        this.phantomMarker.remove();
        this.phantomMarker = undefined;
      }
    });
    marker.getElement().addEventListener('mouseleave', () => this.hoveringOverRealVertex = false);

    if (draggable) {
      marker.on('dragstart', () => {
        this.dragging = true;
      })
      marker.on('dragend', () => {
        this.dragging = false;

        if (!this.editing) {
          marker.setLngLat(thisVertex!.coords);
          return;
        }

        if (thisVertex) {
          thisVertex.coords = marker.getLngLat();
          this.redrawZone();
        }

        this.edit$.next();
      });
    }

    return marker;
  }

  protected drawPhantomMarker(coords: GeoCoords, strokeColor: string): mapboxgl.Marker {
    const el = document.createElement('div');
    el.className = 'pointer';
    el.style.width = '16px';
    el.style.height = '16px';
    el.innerHTML=`<svg width="16" height="16" style="color: ${strokeColor}">
                    <use xlink:href="../../../../assets/map-icons/zone-vertex.svg#c"></use>
                  </svg>`;

    // make it immune to the hover effect and bubble the effect through
    el.style.pointerEvents = 'none';

    return new mapboxgl.Marker(el).setLngLat(coords).addTo(this.map);
  }

  protected createVertexControlModal(): HTMLElement {
    const el = document.createElement('div');
    el.className = 'vertex-controls-modal';
    const closeDiv = document.createElement('div');
    closeDiv.style.width = '44px';
    closeDiv.style.height = '24px';
    closeDiv.className = 'pointer display-flex-centered';
    closeDiv.innerHTML = `
    <svg style="color: #FF3B30;">
        <use xlink:href="../../../../assets/project-icons/close.svg#c"></use>
    </svg>
    `;
    el.appendChild(closeDiv);

    closeDiv.addEventListener('click', (ev: any) => {
      //removing selected vertex
      this.removeConnectionByEndVertex(this.selectedVertex!);
      this.selectedVertex!.data!.marker.remove();
      this.orderedVertices.splice(this.orderedVertices.indexOf(this.selectedVertex!), 1);
      delete this.zone.vertices[this.selectedVertex!.id!];

      if (this.orderedVertices.length) {
        this.selectVertex(this.orderedVertices.at(-1)!);
      }

      this.updateZoneName();

      this.updateZoneOutlineOnMap();
      this.onZoneChange();

      if (this.orderedVertices.length === 0) {
        this.removeSelfSubj$.next();
      }
      this.edit$.next();

      ev.stopPropagation();
    });

    if (this.zone.connections.length >= 2) {
      el.className = 'vertex-controls-modal wider';

      const confirmDiv = document.createElement('div');
      confirmDiv.style.width = '44px';
      confirmDiv.style.height = '24px';
      confirmDiv.className = 'pointer display-flex-centered';
      confirmDiv.style.borderLeft = '1px solid #E2E8F0';
      confirmDiv.innerHTML =`
      <svg style="color: #34C759;">
        <use xlink:href="../../../../assets/project-icons/check.svg#c"></use>
      </svg>
      `;
      el.appendChild(confirmDiv);

      confirmDiv.addEventListener('click', (ev: any) => {
        this.updateZoneOutlineOnMap(true);
        this.updateZoneFillOnMap();
        this.stopEditing();
        this.zoneConfirmed = true;
        this.zoneConfirmSubj$.next(this.zone);
        this.updateZoneName();
        this.edit$.next();

        ev.stopPropagation();
      });
    }

    return el
  }

  protected removeVertexControlModalFromMarker(marker: mapboxgl.Marker): void {
    const elements = marker.getElement().getElementsByClassName('vertex-controls-modal');
    if (elements && elements.length) {
      elements.item(0).remove();
    }
  }

  protected updateZoneName(): void {
    const numberOfVertices = Object.keys(this.zone.vertices).length;
    if (numberOfVertices === 0) {
      this.zoneName = translateObject('zones.empty') as string + ' ';
      return;
    }

    this.zoneName = `${translateObject("zones.vertices")}: ${numberOfVertices}${this.zoneConfirmed ? "" : ". " + translateObject("zones.notClosed")}`;
  }

  private selectVertex(vertex: QGraphVertex<VertexData>): void {
    this.selectedVertex = vertex;
    this.selectedVertex!.data!.selected = true;

    this.getStrokeColor$().pipe(take(1)).subscribe(color => {
      if (!this.selectedVertex!.data?.marker) {
        this.selectedVertex!.data!.marker = this.drawVertexMarker(vertex!.coords, vertex, color, this.markersDraggable);
      }

      if (this.selectedVertex!.data!.marker.getElement().getElementsByClassName('vertex-controls-modal').length === 0) {
        this.selectedVertex!.data!.marker.getElement().appendChild(this.createVertexControlModal());
      }
    });
  }

  private unselectCurrentVertex(): void {
    if (!this.selectedVertex) {
      return;
    }

    this.selectedVertex.data!.selected = false;
    this.removeVertexControlModalFromMarker(this.selectedVertex.data!.marker);
    this.prevSelectedVertex = this.selectedVertex;
  }

  protected updateZoneOutlineOnMap(closeZone = false): void {
    if (this.fillSourceName) {
      if (closeZone) {
        if (this.zone.connections && this.zone.connections.length < Object.keys(this.zone.vertices).length) {
          this.zone.connections.push({
            startVertexId: this.zone.connections[this.zone.connections.length - 1].endVertexId,
            endVertexId: this.zone.connections[0].startVertexId
          });
        }
      } else {
        if (this.zone.connections && this.zone.connections.length === Object.keys(this.zone.vertices).length) {
          this.zone.connections.splice(this.zone.connections.length - 1, 1);
        }
      }
    }

    this.redrawZone();
  }

  private redrawZone(): void {
    const features = [zoneToFeatureLineString(this.zone)];
    this.lastStrokeGeoJSONFeatures = [...features];

    if (this.cluster) {
      for (const inst of this.cluster.instances) {
        if (inst !== this) {
          features.push(...inst.getData().lastStrokeGeoJSONFeatures)
        }
      }
    }

    (this.map.getSource(this.outlineSourceName) as mapboxgl.GeoJSONSource).setData(
      {
        type: 'FeatureCollection',
        features
      }
    );
  }

  private removeConnectionByEndVertex(vertex: QGraphVertex<VertexData>): void {
    const thisConnection = this.zone.connections!.find(value => value.endVertexId === vertex.id);
    if (!thisConnection) {
      //this may mean that we removed the first vertex
      const firstConnection = this.zone.connections!.find(value => value.startVertexId === vertex.id);
      if (firstConnection) {
        this.zone.connections!.splice(this.zone.connections!.indexOf(firstConnection), 1);
      }

      return;
    }

    const nextConnection = this.zone.connections!.find(value => value.startVertexId === vertex.id);

    //thisConnection may be the last one, so there is no next connection
    if (nextConnection) {
      nextConnection.startVertexId = thisConnection.startVertexId;
    }

    this.zone.connections!.splice(this.zone.connections!.indexOf(thisConnection), 1);
  }

  protected updateZoneFillOnMap(empty = false): void {
    if (!this.fillSourceName) {
      return;
    }

    const features = !empty ? [zoneToFeaturePolygon(this.zone)] : [];
    this.lastPolygonGeoJSONFeatures = [...features];

    if (this.cluster) {
      for (const inst of this.cluster.instances) {
        if (inst !== this) {
          features.push(...inst.getData().lastPolygonGeoJSONFeatures)
        }
      }
    }

    (this.map.getSource(this.fillSourceName) as mapboxgl.GeoJSONSource).setData(
      {
        type: 'FeatureCollection',
        features
      }
    );
  }

  protected onZoneChange(): void {
    // if (this.zone.connections!.length >= 2) {
    //   this.mapControlPanelRequestSubject$.next({
    //     state: 'show',
    //     showConfirm: true
    //   });
    // } else {
    //   this.mapControlPanelRequestSubject$.next({
    //     state: 'hide',
    //     showConfirm: false
    //   });
    // }
  }

  protected toCleanZone(): QGraph {
    const cleanZone: QGraph = {
      vertices: {},
      connections: JSON.parse(JSON.stringify(this.zone.connections))
    }

    for (const [key, val] of Object.entries(this.zone.vertices)) {
      cleanZone.vertices[key] = {
        id: key,
        coords: val.coords
      }
    }
    return cleanZone
  }

  protected toFunctionalZone(cleanZone: QGraph): [QGraph<VertexData>, QGraphVertex<VertexData>[]] {
    const functionalZone: QGraph<VertexData> = {
      vertices: {},
      connections: JSON.parse(JSON.stringify(cleanZone.connections))
    }

    for (const [key, val] of Object.entries(cleanZone.vertices)) {
      const vertex: QGraphVertex<VertexData> = {
        id: key,
        coords: val.coords,
        data: {
          selected: false,
          marker: null
        }
      };

      // this one can be called from super(), soe then the observable will be undefined yet in the child class
      if (this.getStrokeColor$()) {
        this.getStrokeColor$().pipe(take(1)).subscribe(color => {
          if (!vertex.data?.marker) {
            vertex.data!.marker = this.drawVertexMarker(vertex.coords, vertex, color, this.markersDraggable);
          }
        });
      }

      functionalZone.vertices[key] = vertex;
    }

    const orderedVertices: QGraphVertex<VertexData>[] = [];

    if (functionalZone.connections?.length) {
      orderedVertices.push(functionalZone.vertices[functionalZone.connections[0].startVertexId]);
    }

    // this was bugged, but leaving it here for a bit, maybe I'll remember why it was written like this
    // if (functionalZone.connections?.length) {
    //   orderedVertices.push(functionalZone.vertices[functionalZone.connections![0].startVertexId]);
    //   orderedVertices[0].data!.selected = true;
    // } else if (Object.keys(functionalZone.vertices).length){
    //   orderedVertices.push(functionalZone.vertices[Object.keys(functionalZone.vertices)[0]])
    //   orderedVertices[0].data!.selected = true;
    // }

    for (const conn of functionalZone.connections!) {
      orderedVertices.push(functionalZone.vertices[conn.endVertexId]);
    }
    orderedVertices[orderedVertices.length - 1].data!.selected = true;
    return [functionalZone, orderedVertices]
  }

  stopEdit$(): Observable<void> {
    return this.stopEditSubj$.asObservable();
  }

  destroy(): void {
    this.removeSelfSubj$.complete();
    this.zoneConfirmSubj$.complete();
    this.stopEditSubj$.complete();
    this.edit$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
