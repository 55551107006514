import {GenericZoneEditEngine} from './generic-zone-edit-engine.class';
import {Observable, of} from 'rxjs';
import {MapControlPanelAction, MapEditEngineInstancesCluster} from './map-edit-engine.interface';
// @ts-ignore
import * as mapboxgl from 'mapbox-gl';
import {QGraph} from '../../interfaces/geo-and-movement.interfaces';

export class HazardZoneEditEngine extends GenericZoneEditEngine {
  constructor(map: mapboxgl.Map,
              mapControlPanelAction$: Observable<MapControlPanelAction>,
              cluster: MapEditEngineInstancesCluster<HazardZoneEditEngine>,
              zone?: QGraph,
              callbackOnAnyClick?: (zoneEngine: GenericZoneEditEngine) => void) {
    super(map, mapControlPanelAction$, 'hazard-zones', 'hazard-zones-outline', false, cluster, zone, callbackOnAnyClick);
    this.postInit();
  }

  getStrokeColor$(): Observable<string> {
    return of('#FF3B30');
  }

}
