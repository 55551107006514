import {AfterContentChecked, Component, computed, input, OnInit, output} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {TranslocoDirective, TranslocoPipe} from '@ngneat/transloco';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {SvgWrapperComponent} from '../svg-wrapper/svg-wrapper.component';
import {CommentsEditEngine} from '../../utils/map-edit-engines/comments-edit-engine.class';
import {NgClass} from '@angular/common';

@Component({
  selector: 'lib-comment-section-editor',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslocoDirective,
    MatFormField,
    MatInput,
    MatLabel,
    SvgWrapperComponent,
    NgClass,
    TranslocoPipe
  ],
  templateUrl: './comment-section-editor.component.html',
  styleUrl: './comment-section-editor.component.scss'
})
export class CommentSectionEditorComponent implements OnInit, AfterContentChecked{

  commentFormGroup = input.required<FormGroup>();
  editEngine = input.required<CommentsEditEngine>();
  imageInputId = computed(() => 'add-photo-input-' + this.commentFormGroup().value.uuid);

  listenerSetUp = false;

  deleteSelf = output<void>();

  ngOnInit() {
    this.commentFormGroup().valueChanges.subscribe((value) => {
      this.editEngine().updateComment(value.text, this.commentFormGroup().value.imageUrl, true);
    });

    this.editEngine().onEdit$().subscribe(() => {
      this.commentFormGroup().patchValue({
        coords: this.editEngine().getData().coords
      });
    });
  }

  ngAfterContentChecked(): void {
    if (this.listenerSetUp) {
      return;
    }

    const addPhotoInput = document.getElementById(this.imageInputId());

    if (!addPhotoInput) {
      return;
    }

    addPhotoInput!.addEventListener('change', (event: any) => {
      const files = event?.target?.files;
      if (!files || files.length === 0) {
        console.error('No files uploaded');
        return;
      }

      this.readImage(files[0]);
    });

    this.listenerSetUp = true;
  }

  addPhoto(): void {
    const addPhotoInput = document.getElementById(this.imageInputId());
    addPhotoInput?.click();
  }

  delete(): void {
    this.editEngine().clear();

    this.deleteSelf.emit();
  }

  private readImage(file: any): void {
    const reader = new FileReader();
    const onLoadHandler = () => {
      this.commentFormGroup().patchValue({
        imageUrl: reader.result
      });

      reader.removeEventListener('load', onLoadHandler);
    };

    reader.addEventListener('load', onLoadHandler);

    reader.readAsDataURL(file);
  }
}
