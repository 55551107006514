import {MapControlPanelRequestBody, MapEditEngine} from './map-edit-engine.interface';
import {NEVER, Observable, of, Subject, takeUntil} from 'rxjs';
import * as mapboxgl from 'mapbox-gl';
import {MapboxSpecificService} from '../../services/mapbox-specific.service';
import * as turf from '@turf/turf';
import {FormControl} from '@angular/forms';
import {GeoCoords, QGraphVertexWithRadius} from '../../interfaces/geo-and-movement.interfaces';

export class StartZoneCircleEditEngine implements MapEditEngine {
  editing: boolean;
  zoneCenterMarker?: mapboxgl.Marker;
  circleZone: QGraphVertexWithRadius;
  name = '...';
  radiusControl = new FormControl<number>(10);

  private stopEditSubj$ = new Subject<void>();
  private edit$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor(private map: mapboxgl.Map, private mapboxSpecificService: MapboxSpecificService, startZone?: QGraphVertexWithRadius) {
    this.circleZone = startZone;

    if (this.circleZone) {
      this.radiusControl.setValue(this.circleZone.data.radius);

      this.map.on('load', () => {
        setTimeout(() => {
          this.drawCircleZone(this.circleZone);
        }, 100);
      });

      this.mapboxSpecificService.reverseGeocoding(this.circleZone.coords).subscribe(value => {
        this.name = value.features[0].place_name;
      });
    }

    this.radiusControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (!this.circleZone) {
        return;
      }

      this.circleZone.data.radius = value;
      this.drawCircle(this.circleZone);
    });
  }

  postInit() {
  }

  clear(): void {
    this.circleZone = null;

    if (this.zoneCenterMarker) {
      this.zoneCenterMarker.remove();
    }
    this.edit$.next();

    (this.map.getSource('start-zones') as mapboxgl.GeoJSONSource).setData(turf.featureCollection([]));
    (this.map.getSource('start-zone-outline') as mapboxgl.GeoJSONSource).setData(turf.featureCollection([]));
  }

  removeSelf$(): Observable<void> {
    return NEVER;
  }

  clickOnMap(clickCoords: GeoCoords): void {
    let radius = this.radiusControl.value;
    if (this.circleZone) {
      radius = this.circleZone.data.radius;
    }

    this.clear();

    this.circleZone = {
      id: 'start-circle-zone',
      coords: clickCoords,
      data: {radius}
    }

    this.drawCircleZone(this.circleZone);

    this.mapboxSpecificService.reverseGeocoding(clickCoords).subscribe(value => {
      this.name = value.features[0].place_name;
    });
    this.edit$.next();

    this.stopEditing();
  }

  hoverOnMap(coords: GeoCoords) {}

  externalRedraw(): void {
    if (!this.circleZone) {
      return;
    }

    this.drawCircle(this.circleZone);
  }

  getData(): any {
    if (!this.circleZone) {
      return {
        empty: true
      };
    }

    return {
      circleZone: this.circleZone,
      name: this.name
    };
  }

  isValid(): boolean {
    return true;
  }

  mapControlPanelRequest$(): Observable<MapControlPanelRequestBody> {
    return of<MapControlPanelRequestBody>({state: 'hide'});
  }

  nonEditClickOnMap(clickCoords: GeoCoords): void {
  }

  startEditing(): void {
    this.editing = true;
  }

  stopEditing(): void {
    this.editing = false;
    this.stopEditSubj$.next();
  }

  onEdit$(): Observable<void> {
    return this.edit$.asObservable();
  }

  protected drawCircleZone(zoneData: QGraphVertexWithRadius): void {
    const el = document.createElement('div');
    el.style.width = '16px';
    el.style.height = '16px';
    el.innerHTML = `<svg width="16" height="16" style="color: #34C759">
                    <use xlink:href="../../../../assets/map-icons/zone-vertex.svg#c"></use>
                  </svg>`

    this.zoneCenterMarker = new mapboxgl.Marker(el).setLngLat(zoneData.coords).addTo(this.map);

    this.drawCircle(zoneData);
  }

  protected drawCircle(zoneData: QGraphVertexWithRadius): void {
    const circle = turf.circle([zoneData.coords.lng, zoneData.coords.lat], zoneData.data.radius, {steps: 60, units: 'meters'});

    (this.map.getSource('start-zones') as mapboxgl.GeoJSONSource).setData(circle);
    (this.map.getSource('start-zone-outline') as mapboxgl.GeoJSONSource).setData(circle);
  }

  stopEdit$(): Observable<void> {
    return this.stopEditSubj$.asObservable();
  }

  destroy(): void {
    this.stopEditSubj$.complete();

    this.edit$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
