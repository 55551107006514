import {GenericZoneEditEngine} from './generic-zone-edit-engine.class';
import * as mapboxgl from 'mapbox-gl';
import {Observable} from 'rxjs';
import {MapControlPanelAction} from './map-edit-engine.interface';
import {QGraph} from '../../interfaces/geo-and-movement.interfaces';

export class PathwayEditEngine extends GenericZoneEditEngine {
  constructor(map: mapboxgl.Map,
              mapControlPanelAction$: Observable<MapControlPanelAction>,
              outlineName: string,
              private strokeColor$: Observable<string>,
              zone?: QGraph,
              callbackOnAnyClick?: (zoneEngine: GenericZoneEditEngine) => void) {
    super(map, mapControlPanelAction$, undefined, outlineName, true, undefined, zone, callbackOnAnyClick);
    this.postInit();
  }

  getStrokeColor$(): Observable<string> {
    return this.strokeColor$;
  }
}
