import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {isObeliskZoneCircle, Marker, Obelisk, ObeliskImage} from '../interfaces/exploration.interfaces';
import {GeoBBox, GeoCoords, QGraph} from '../interfaces/geo-and-movement.interfaces';
import {Dict} from '../interfaces/common.interfaces';
import {EnvProviderService} from './env-provider.service';
import {ObeliskImportance} from '../interfaces/obelisks.enums';
import moment from 'moment/moment';
import {Cluster} from '../interfaces/cluster.interfaces';
import {transformClusterFromServer} from '../functions/exploration.functions';

export interface LoadObelisksParams {
  orderBy?: 'distance' | 'importance';
  location?: GeoCoords;
  adminMode?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ExplorationService {

  readonly ROOT_URL = '/exploration';

  constructor(private http: HttpClient, private env: EnvProviderService) { }

  public loadObelisks$(importance: ObeliskImportance[], bbox?: GeoBBox, search = '', params: LoadObelisksParams = {}, limit?: number): Observable<Obelisk[]> {
    let httpParams = new HttpParams().set('importance', importance.join(','));

    if (bbox) {
      httpParams = httpParams.append('bbox', `${bbox.sw.lng},${bbox.sw.lat},${bbox.ne.lng},${bbox.ne.lat}`);
    }

    if (search) {
      httpParams = httpParams.set('search', search);
    }

    if (limit) {
      httpParams = httpParams.set('limit', limit.toString());
    }

    if (params.orderBy) {
      httpParams = httpParams.set('orderBy', params.orderBy);
    }

    if (params.location) {
      httpParams = httpParams.set('location', `${params.location.lng},${params.location.lat}`);
    }

    if (params.adminMode) {
      httpParams = httpParams.set('adminMode', 'true');
    }

    return this.http.get<Obelisk[]>(`${this.env.environment.API_URL}${this.ROOT_URL}/`, {params: httpParams}).pipe(
      map((rawObelisks) => rawObelisks.map((rawObelisk) => this.transformObeliskFromServer(rawObelisk)))
    );
  }

  createObelisk$(obelisk: Obelisk): Observable<unknown> {
    return this.http.post(`${this.env.environment.API_URL}${this.ROOT_URL}/`, this.transformObeliskForServer(obelisk));
  }

  updateObelisk$(obelisk: Obelisk): Observable<unknown> {
    return this.http.patch(`${this.env.environment.API_URL}${this.ROOT_URL}/${obelisk.uuid}`, this.transformObeliskForServer(obelisk));
  }

  getObeliskById$(id: number): Observable<Obelisk> {
    return this.http.get<Obelisk>(`${this.env.environment.API_URL}${this.ROOT_URL}/${id}`)
      .pipe(
        map((raw) => this.transformObeliskFromServer(raw))
      );
  }

  deleteObelisk$(obelisk: Obelisk): Observable<unknown> {
    return this.http.delete(`${this.env.environment.API_URL}${this.ROOT_URL}/${obelisk.uuid}`);
  }

  approveObelisk$(obelisk: Obelisk, rewardsExp?: number): Observable<unknown> {
    return this.http.put(`${this.env.environment.API_URL}${this.ROOT_URL}/${obelisk.uuid}/approve`, {rewards_exp: rewardsExp});
  }

  rejectObelisk$(obelisk: Obelisk): Observable<unknown> {
    return this.http.put(`${this.env.environment.API_URL}${this.ROOT_URL}/${obelisk.uuid}/reject`, {});
  }

  public loadMarkers$(bbox?: GeoBBox, search = '', params: LoadObelisksParams = {}, limit?: number): Observable<Marker[]> {
    let httpParams = new HttpParams();

    if (bbox) {
      httpParams = httpParams.append('bbox', `${bbox.sw.lng},${bbox.sw.lat},${bbox.ne.lng},${bbox.ne.lat}`);
    }

    if (search) {
      httpParams = httpParams.set('search', search);
    }

    if (limit) {
      httpParams = httpParams.set('limit', limit.toString());
    }

    if (params.orderBy) {
      httpParams = httpParams.set('orderBy', params.orderBy);
    }

    if (params.location) {
      httpParams = httpParams.set('location', `${params.location.lng},${params.location.lat}`);
    }

    return this.http.get<Marker[]>(`${this.env.environment.API_URL}${this.ROOT_URL}/markers`, {params: httpParams}).pipe(
      map((rawMarkers) => rawMarkers.map((rawMarker) => this.transformMarkerFromServer(rawMarker)))
    );
  }

  getMarkerById$(id: string): Observable<Marker> {
    return this.http.get<Marker>(`${this.env.environment.API_URL}${this.ROOT_URL}/markers/${id}`)
      .pipe(
        map((raw) => this.transformMarkerFromServer(raw))
      );
  }

  public createMarker$(marker: Marker): Observable<Marker> {
    const body = this.transformMarkerForServer(marker);

    return this.http.post<Marker>(`${this.env.environment.API_URL}${this.ROOT_URL}/markers`, body).pipe(
      map((rawMarker) => this.transformMarkerFromServer(rawMarker))
    );
  }

  public updateMarker$(marker: Marker): Observable<Marker> {
    const body = this.transformMarkerForServer(marker);

    return this.http.patch<Marker>(`${this.env.environment.API_URL}${this.ROOT_URL}/markers/${marker.uuid}`, body).pipe(
      map((rawMarker) => this.transformMarkerFromServer(rawMarker))
    );
  }

  public deleteMarker$(markerUuid: string): Observable<unknown> {
    return this.http.delete<unknown>(`${this.env.environment.API_URL}${this.ROOT_URL}/markers/${markerUuid}`);
  }

  public loadClusters$(importance: ObeliskImportance[], mapHeight: number, bbox?: GeoBBox, search = ''): Observable<Cluster[]> {
    let params = new HttpParams().set('importance', importance.join(',')).set('mapHeight', mapHeight.toString());

    if (bbox) {
      params = params.append('bbox', `${bbox.sw.lng},${bbox.sw.lat},${bbox.ne.lng},${bbox.ne.lat}`);
    }

    if (search) {
      params = params.set('search', search);
    }

    return this.http.get<any[]>(`${this.env.environment.API_URL}${this.ROOT_URL}/clusters`, {params}).pipe(
      map(clusters => clusters.map(cluster => transformClusterFromServer(cluster)))
    );
  }

  private transformObeliskFromServer(raw: any): Obelisk {
    const createdAt = new Date(raw.created_at);
    const updatedAt = new Date(raw.updated_at);

    const obelisk: Obelisk = {
      uuid: raw.uuid,
      name: raw.name,
      subTitle: raw.sub_title,
      description: raw.description,
      creator: {
        email: raw.creator_email,
        username: raw.creator_username,
        firstName: raw.creator_first_name,
        lastName: raw.creator_last_name,
        isSuperUser: raw.creator_is_superuser
      },
      location: {
        lng: raw.lng,
        lat: raw.lat,
      },
      svg: raw.svg,
      type: raw.type,
      status: raw.status,
      category: raw.category,
      address: raw.address,
      approved: raw.approved,
      importance: raw.importance,
      rewardsExp: raw.rewards_exp,
      zone: raw.zone,
      config: raw.config,
      images: (raw.images || []).map((image: any) => ({
        uuid: image.uuid,
        uri: image.uri,
        saved: true,
        order: image.order,
      }) as ObeliskImage),
      createdAt,
      updatedAt,
      createdAgo: moment(createdAt).fromNow(),
      updatedAgo: moment(updatedAt).fromNow()
    };

    if (!isObeliskZoneCircle(obelisk.zone)) {
      const absolutePolygon: QGraph = {
        vertices: {},
        connections: []
      };

      obelisk.zone.vertices.forEach((vertex, index) => {
        const vertexId = `v${index}`;
        absolutePolygon.vertices[vertexId] = {
          id: vertexId,
          coords: {
            lng: obelisk.location.lng - vertex.lngDiff,
            lat: obelisk.location.lat - vertex.latDiff,
          }
        };

        if (index > 0) {
          absolutePolygon.connections.push({
            startVertexId: `v${index - 1}`,
            endVertexId: vertexId,
          });
        }
      });

      absolutePolygon.connections.push({
        startVertexId: `v${obelisk.zone.vertices.length - 1}`,
        endVertexId: 'v0',
      });

      obelisk.zone.absolutePolygon = absolutePolygon;
    }

    return obelisk;
  }

  private transformObeliskForServer(obelisk: Obelisk): Dict<any> {
    return {
      name: obelisk.name,
      sub_title: obelisk.subTitle,
      description: obelisk.description,
      lng: obelisk.location.lng,
      lat: obelisk.location.lat,
      svg: obelisk.svg,
      type: obelisk.type,
      status: 'place',
      category: obelisk.category,
      address: obelisk.address,
      images: obelisk.images,
      importance: obelisk.importance,
      zone: obelisk.zone,
      config: obelisk.config
    }
  }

  private transformMarkerFromServer(raw: any): Marker {
    return {
      uuid: raw.uuid,
      name: raw.name,
      creator: {
        id: raw.creator_id,
        email: raw.creator_email,
        username: raw.creator_username,
        firstName: raw.creator_first_name,
        lastName: raw.creator_last_name,
        isSuperUser: raw.creator_is_superuser
      },
      description: raw.description,
      location: {
        lng: raw.lng,
        lat: raw.lat,
      },
      status: 'marker',
      address: raw.address,
      config: raw.config,
      distance: raw.distance,
      images: (raw.images || []).sort((a, b) => a.order - b.order),
      createdAt: new Date(raw.created_at),
      updatedAt: new Date(raw.updated_at),
      additionalData: {}
    }
  }

  private transformMarkerForServer(marker: Marker): any {
    return {
      name: marker.name,
      description: marker.description,
      lng: marker.location.lng,
      lat: marker.location.lat,
      address: marker.address,
      config: marker.config,
      images: marker.images,
    };
  }
}
