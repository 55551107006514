import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FeatureCollection} from 'geojson';
import {GeoCoords} from '../interfaces/geo-and-movement.interfaces';
import {EnvProviderService} from './env-provider.service';

@Injectable({
  providedIn: 'root'
})
export class MapboxSpecificService {

  constructor(private http: HttpClient, private env: EnvProviderService) { }

  reverseGeocoding(coords: GeoCoords): Observable<any> {
    return this.http.get<any>(`https://api.mapbox.com/geocoding/v5/mapbox.places/${coords.lng},${coords.lat}.json`,
      {params: {access_token: this.env.environment.mapboxToken, routing: true}})
  }

  forwardGeocoding(search: string): Observable<any> {
    return this.http.get<any>(`https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json`,
      {params: {access_token: this.env.environment.mapboxToken, routing: true, autocomplete: false}});
  }

  isochrone(coords: GeoCoords, minutes: number): Observable<FeatureCollection> {
    return this.http.get<any>(`https://api.mapbox.com/isochrone/v1/mapbox/walking/${coords.lng},${coords.lat}`,
      {params: {contours_minutes: minutes, generalize: 10, polygons: false, access_token: this.env.environment.mapboxToken}})
  }

  mapMatching(coords: GeoCoords[], movingType: 'walking' | 'cycling'): Observable<any> {
    const params = new HttpParams()
      .set('coordinates', coords.map(c => `${c.lng},${c.lat}`).join(';'))
      .set('geometries', 'geojson')
      .set('radiuses', coords.map(c => '30').join(';'))
      .set('steps', 'false');

    return this.http.post<any>(`https://api.mapbox.com/matching/v5/mapbox/${movingType}`,
      params,
      {
        params: {access_token: this.env.environment.mapboxToken},
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
      });
  }
}
