import {MapControlPanelRequestBody, MapEditEngine} from './map-edit-engine.interface';
import * as mapboxgl from 'mapbox-gl';
import {NEVER, Observable, of, Subject} from 'rxjs';
import {MapboxSpecificService} from '../../services/mapbox-specific.service';
import {GeoCoords} from '../../interfaces/geo-and-movement.interfaces';
import {drawMarkerOnMap} from '../../functions/map-general.functions';

export class MarkerReachEditEngine implements MapEditEngine {

  editing = false;
  markerToReach: mapboxgl.Marker;
  coords?: GeoCoords;
  currentAddress?: string = '...';

  private edit$ = new Subject<void>();
  private stopEditSubj$ = new Subject<void>();

  constructor(private map: mapboxgl.Map, private mapboxSpecificService: MapboxSpecificService, markerCoords?: GeoCoords) {
    if (markerCoords) {
      this.markerToReach = this.drawReachMarker(markerCoords)

      this.mapboxSpecificService.reverseGeocoding(markerCoords).subscribe(value => {
        this.currentAddress = value.features[0].place_name;
      });
    }
  }

  postInit() {
  }

  removeSelf$(): Observable<void> {
    return NEVER;
  }

  clickOnMap(clickCoords: GeoCoords): void {
    if (!this.editing) {
      return;
    }
    if (this.markerToReach) {
      this.removeMarkerToReach();
    }

    this.markerToReach = this.drawReachMarker(clickCoords);

    this.mapboxSpecificService.reverseGeocoding(clickCoords).subscribe(value => {
      this.currentAddress = value.features[0].place_name;
    });

    this.stopEditing();

    this.edit$.next();
  }

  hoverOnMap(coords: GeoCoords) {}

  clear(): void {
    if (this.markerToReach) {
      this.removeMarkerToReach();

      this.edit$.next();
    }
  }

  startEditing(): void {
    this.editing = true;
  }

  stopEditing(): void {
    this.editing = false;
    this.stopEditSubj$.next();
  }

  getData(): any {
    return {
      marker: this.markerToReach,
      coords: this.coords,
      name: this.currentAddress
    }
  }

  externalRedraw(): void { }

  private removeMarkerToReach(): void {
    this.markerToReach.remove();
    this.markerToReach = undefined;
    this.coords = undefined;
    this.currentAddress = '...';
  }

  private drawReachMarker(coords: GeoCoords): mapboxgl.Marker {
    this.coords = coords;
    return drawMarkerOnMap(coords, this.map, '../../../../assets/map-icons/active-marker-1.svg');
  }

  mapControlPanelRequest$(): Observable<MapControlPanelRequestBody> {
    return of<MapControlPanelRequestBody>({state: 'hide'});
  }

  isValid(): boolean {
    return !!this.coords;
  }

  nonEditClickOnMap(clickCoords: GeoCoords): void {
  }

  stopEdit$(): Observable<void> {
    return this.stopEditSubj$.asObservable();
  }

  onEdit$(): Observable<void> {
    return this.edit$.asObservable();
  }

  destroy(): void {
    this.edit$.complete()
    this.stopEditSubj$.complete()
  }
}
