import {QGraph, QGraphVertexWithCircle, QGraphVertexWithRadius} from '../interfaces/geo-and-movement.interfaces';
import * as mapboxgl from 'mapbox-gl';
import {isObeliskZoneCircle, Obelisk} from '../interfaces/exploration.interfaces';
import {ObeliskImportance} from '../interfaces/obelisks.enums';
import {Cluster} from '../interfaces/cluster.interfaces';
import {Dict} from '../interfaces/common.interfaces';

export const obelisksToZones = (obelisks?: Obelisk[]): (QGraph | QGraphVertexWithCircle | QGraphVertexWithRadius)[] => {
  return obelisks.filter(value => !!value).map((obelisk) => {
    if (!isObeliskZoneCircle(obelisk.zone)) {
      return obelisk.zone.absolutePolygon;
    }

    return {
      coords: obelisk.location,
      data: {radius: obelisk.zone.radius}
    } as QGraphVertexWithRadius;
  });
}

export const drawObelisk = (obelisk: Obelisk, map: mapboxgl.Map, onClick = () => {}): mapboxgl.Marker => {
  if (!map) {
    return null;
  }

  let dimensions = 42;
  let size = 'big';


  switch (obelisk.importance) {
    case ObeliskImportance.small:
      dimensions = 28;
      size = 'small';
      break;
    case ObeliskImportance.medium:
      dimensions = 36;
      size = 'medium';
      break;
  }
  const div = document.createElement('div');
  div.className = 'map-obelisk-marker-container ' + size;
  if (obelisk.approved) {
    div.className += ' approved';
  }

  const label = document.createElement('div');
  label.className = 'label';
  label.innerText = obelisk.name;
  div.appendChild(label);

  const mainBody = document.createElement('div');
  mainBody.className = 'main-body';
  div.appendChild(mainBody);

  const pin = document.createElement('div');
  pin.className = 'pin';
  div.appendChild(pin);

  if (obelisk.images && obelisk.images.length > 0) {
    const img = document.createElement('img');

    img.src = obelisk.images[0].uri;
    img.width = dimensions;
    img.height = dimensions;
    img.style.backgroundColor = 'lightgrey';

    mainBody.appendChild(img);
  } else {
    if (obelisk.svg?.includes('svg')) {
      obelisk.svg = obelisk.svg.replace('width="24" height="24"', `width="${dimensions}" height="${dimensions}"`);

      const svg = document.createElement('div');
      svg.style.width = `${dimensions}px`;
      svg.style.height = `${dimensions}px`;
      svg.innerHTML = obelisk.svg;

      mainBody.appendChild(svg);
    } else {
      const img = document.createElement('img');

      img.src = `assets/exploration-icons/${obelisk.svg}.svg`;
      img.width = dimensions;
      img.height = dimensions;

      mainBody.appendChild(img);
    }
  }

  const marker = new mapboxgl.Marker(div).setLngLat(obelisk.location).addTo(map);
  mainBody.addEventListener('click', (ev: any) => {
    onClick();

    ev.stopPropagation();
  });

  return marker;
}

export const transformClusterFromServer = (raw: Dict<any>): Cluster => {
  return {
    id: raw['id'],
    itemsCount: raw['count'],
    center: {
      lat: raw['center'].lat,
      lng: raw['center'].lng
    },
    bbox: {
      max: {
        lat: raw['bbox'].max_lat,
        lng: raw['bbox'].max_lng
      },
      min: {
        lat: raw['bbox'].min_lat,
        lng: raw['bbox'].min_lng
      }
    }
  }
}
