<div class="container" *transloco="let t">
  <div class="full-width-container flex flex-row-reverse space-between margin-bottom-12">
    <button class="primary-bar-btn" color="primary" routerLink="/obelisks-editor/new">{{t('addNewPlace')}}</button>
    @if(user()?.isSuperUser) {
      <mat-checkbox [formControl]="showOnlyMine"><span class="text-color-soft">{{t('showOnlyMine')}}</span></mat-checkbox>
    }
    <app-chooser [options]="chooserOptions" (optionChosen)="viewChange($event)"></app-chooser>
  </div>
  @if (viewMode() === 'map') {
    <app-display-map
      [options]="mapOptions"
      [goToMyLocationBtn]="true"
      [rememberLastCenter]="true"
      (clickOnObelisk)="goToEditObelisk($event)"
      (clickOnMarker)="goToCreateObeliskFromMarker($event)"
      (mapMoveEnd)="onMapMoveEnd($event)"
      (mapZoom)="onMapZoom($event)"
    >
    </app-display-map>
  } @else {
    <mat-form-field class="search-input" appearance="outline" (keydown.enter)="loadObelisks(searchBar.value)">
      <input [formControl]="searchBar" matInput placeholder="{{t('search')}}">
      <button matSuffix mat-icon-button aria-label="Search">
        <mat-icon>search</mat-icon>
      </button>
    </mat-form-field>

    <app-obelisks-table-list
      [obelisks]="obelisks"
      (select)="goToEditObelisk($event)"
      (edit)="goToEditObelisk($event)"
      (approve)="obeliskToApprove.set($event); xpForApprovedObelisk.setValue(obeliskToApprove().rewardsExp); showApproveConfirmation.set(true)"
      (reject)="rejectObelisk($event)"
      (delete)="handleDeleteObelisk($event)"
    >
    </app-obelisks-table-list>
  }
  @if (showDeleteConfirmation()) {
    <app-modal
      [title]="t('areYouSureQ')"
      [footerButtons]="deleteModalButtons"
      (closeEvent)="showDeleteConfirmation.set(false); obeliskToDelete.set(null)">
      <p class="text-color-soft">{{t('areYouSureToDelete')}}</p>
    </app-modal>
  }

  @if (showApproveConfirmation()) {
    <app-modal
      [title]="t('approvePlace')"
      [footerButtons]="approveModalButtons"
      (closeEvent)="showApproveConfirmation.set(false); obeliskToApprove.set(null)">
      <p class="text-color-soft">{{t('approvePlaceDescription')}}</p>
      <mat-form-field appearance="fill" class="form-field margin-bottom-12">
        <mat-label>{{t('rewardsExp')}}</mat-label>
        <input matInput type="number" [formControl]="xpForApprovedObelisk">
      </mat-form-field>
    </app-modal>
  }
</div>
