import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
// @ts-ignore
import * as mapboxgl from 'mapbox-gl';
// @ts-ignore
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { QuestStepObjectiveType } from 'quest-atlas-angular-components';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { MapEditorExternalApi } from './map-editor.interfaces';
import { GeoCoords, QGraph, QGraphVertex, QGraphVertexWithCircle, QGraphVertexWithRadius } from 'quest-atlas-angular-components';
import { addLayersToMap } from 'quest-atlas-angular-components';
import { MapboxSpecificService } from 'quest-atlas-angular-components';
import { isStartZoneCircle } from 'quest-atlas-angular-components';
import {metersToPretty, numberToAtLeastTwoCharsStr} from 'quest-atlas-angular-components';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StepsFormData } from '../form-component.interface';
import {TimeSpan} from 'quest-atlas-angular-components';
import {timerFromString} from 'quest-atlas-angular-components';
import {
  MapEditEngine, MarkerReachEditEngine, MarkerFindEditEngine, StartZoneCircleEditEngine, MapControlPanelAction,
  MapControlPanelRequestBody, MapEditEngineInstancesCluster, HazardZoneEditEngine, GenericZoneEditEngine
} from 'quest-atlas-angular-components';

export interface MapConfigData {
  markerReach?: QGraphVertex;
  markerFind?: QGraphVertexWithCircle;
  startZone?: QGraph | QGraphVertexWithRadius;
  hazardZones?: QGraph[];
  startZoneHidden?: boolean;
  timer?: TimeSpan;
}

@Component({
  selector: 'app-map-editor',
  templateUrl: './map-editor.component.html',
  styleUrls: ['./map-editor.component.scss']
})
export class MapEditorComponent implements OnInit, MapEditorExternalApi, OnDestroy {
  readonly QuestStepObjectiveType = QuestStepObjectiveType;

  editMode: 'reachMarker' | 'findMarker' | 'startZone' | 'hazardZone' | 'activeZone' | 'markerPath' | null = null;
  currentEditEngine?: MapEditEngine;

  map!: mapboxgl.Map;
  mapLoaded = false;

  @Input() title = '';
  @Input() questObjectiveType!: QuestStepObjectiveType;
  @Input() config?: MapConfigData = {};
  @Input() stepFormValues?: StepsFormData;
  @Input() userGeo?: GeoCoords;

  @Output() close = new EventEmitter<void>();
  @Output() applyClick = new EventEmitter<MapConfigData>();

  markerReachEditEngine: MarkerReachEditEngine;
  markerFindEditEngine!: MarkerFindEditEngine;
  startZoneEditEngine!: StartZoneCircleEditEngine;

  mapControlPanelActions$ = new Subject<MapControlPanelAction>();
  mapControlVisibility$ = new BehaviorSubject<MapControlPanelRequestBody>({ state: 'hide' });

  hazardZonesCluster: MapEditEngineInstancesCluster<HazardZoneEditEngine> = { instances: [] };

  isTimerVisible = false;

  form!: FormGroup;

  @HostListener('document:keyup', ['$event'])
  keyup(event: any) {
    event.stopPropagation();
  }

  destroy$ = new Subject<void>();

  constructor(private mapboxSpecificService: MapboxSpecificService, private fb: FormBuilder) {}
  ngOnInit(): void {
    // @ts-ignore
    mapboxgl.accessToken = environment.mapboxPublicToke;
    let centerAndZoom = {
      zoom: 2,
      center: [0, 0]
    };

    const lastLocation = this.getLastLocation();

    if (this.config?.markerFind) {
      centerAndZoom = {
        zoom: 14,
        center: [this.config?.markerFind.coords.lng, this.config?.markerFind.coords.lat]
      };
    } else if (this.config?.markerReach) {
      centerAndZoom = {
        zoom: 14,
        center: [this.config?.markerReach.coords.lng, this.config?.markerReach.coords.lat]
      };
    } else if (lastLocation) {
      centerAndZoom = {
        zoom: 14,
        center: [lastLocation.lng, lastLocation.lat]
      };
    } else if (this.userGeo) {
      centerAndZoom = {
        zoom: 13,
        center: [this.userGeo.lng, this.userGeo.lat]
      };
    }

    // @ts-ignore
    this.map = new mapboxgl.Map({
      container: 'map-container',
      style: 'mapbox://styles/mapbox/outdoors-v11',
      ...centerAndZoom
    });

    if (this.questObjectiveType === QuestStepObjectiveType.markerReach) {
      this.markerReachEditEngine = new MarkerReachEditEngine(this.map, this.mapboxSpecificService, this.config?.markerReach?.coords);
      this.markerReachEditEngine
        .stopEdit$()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.currentEditEngine = undefined;
          this.editMode = null;
        });
    } else if (this.questObjectiveType === QuestStepObjectiveType.markerFind) {
      this.markerFindEditEngine = new MarkerFindEditEngine(this.map, this.mapboxSpecificService, this.config?.markerFind);
      this.markerFindEditEngine
        .stopEdit$()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.currentEditEngine = undefined;
          this.editMode = null;
        });
    }

    //this is to support older quests, where start zones were polygons
    //remove this later
    if (!isStartZoneCircle(this.config?.startZone)) {
      this.config.startZone = null;
    }

    this.startZoneEditEngine = new StartZoneCircleEditEngine(this.map, this.mapboxSpecificService, this.config?.startZone as QGraphVertexWithRadius);

    //this is, so that from inside edit engines we can show map controls buttons
    this.markerFindEditEngine
      ?.mapControlPanelRequest$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.mapControlVisibility$.next(value);
      });
    this.startZoneEditEngine
      .mapControlPanelRequest$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.mapControlVisibility$.next(value);
      });

    this.startZoneEditEngine
      .stopEdit$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.currentEditEngine = undefined;
        this.editMode = null;
      });

    this.config?.hazardZones?.forEach((value) => {
      this.addNewHazardZone(value);
    });

    // Add map controls
    this.map.addControl(
      new MapboxGeocoder({
        accessToken: environment.mapboxPublicToke,
        mapboxgl: mapboxgl
      })
    );

    this.map.addControl(new mapboxgl.NavigationControl());

    this.map.on('load', () => {
      this.mapLoaded = true;

      addLayersToMap(this.map);
    });

    this.map.on('click', (ev: any) => {
      if (this.currentEditEngine) {
        this.storeLastLocation(ev.lngLat);
        this.currentEditEngine.clickOnMap(ev.lngLat);
      }

      this.hazardZonesCluster.instances.forEach((value) => {
        if (!value.editing) {
          value.nonEditClickOnMap(ev.lngLat);
        }
      });
    });

    this.form = this.fb.group({
      timer: [
        //todo move into utils file & refactor
        this.config.timer
          ? Object.values(this.config.timer).reduce(
              (acc, item, index) =>
                `${acc}${
                  index < Object.values(this.config.timer).length && index > 0
                    ? `:${numberToAtLeastTwoCharsStr(item)}`
                    : `${numberToAtLeastTwoCharsStr(item)}`
                }`,
              ''
            )
          : ''
      ]
    });
  }

  toggleMagStyle() {
    if (this.map.getStyle().name === 'Mapbox Outdoors') {
      this.map.setStyle('mapbox://styles/mapbox/satellite-v9');
    } else {
      this.map.setStyle('mapbox://styles/mapbox/outdoors-v11');
    }

    this.map.once('styledata', () => {
      addLayersToMap(this.map);

      setTimeout(() => {
        this.map.triggerRepaint();
        if (this.markerFindEditEngine) {
          this.markerFindEditEngine.externalRedraw();
        }
        this.startZoneEditEngine.externalRedraw();

        this.hazardZonesCluster.instances.forEach((hz) => {
          hz.externalRedraw();
        });
      }, 100);
    });
  }

  apply(): MapConfigData {
    let timer: TimeSpan = timerFromString(this.form.get('timer')?.value as string);

    const result: MapConfigData = {
      startZone: this.startZoneEditEngine.getData().circleZone,
      hazardZones: this.hazardZonesCluster.instances
        .filter((value) => {
          return value.getData().cleanZone.connections.length >= 2;
        })
        .map((value) => {
          return value.getData().cleanZone;
        }),
      timer
    };

    // if (result.startZone && result.startZone.connections!.length < 2) {
    //   delete result.startZone
    // }

    switch (this.questObjectiveType) {
      case QuestStepObjectiveType.markerReach:
        result.markerReach = { coords: this.markerReachEditEngine.getData().coords };
        break;
      case QuestStepObjectiveType.markerFind:
        result.markerFind = this.markerFindEditEngine.getData().marker;
    }

    return result;
  }

  isValid(): boolean {
    for (const inst of this.hazardZonesCluster.instances) {
      if (!inst.isValid()) {
        return false;
      }
    }

    if (this.questObjectiveType === QuestStepObjectiveType.markerReach) {
      if (!this.markerReachEditEngine.isValid()) {
        return false;
      }
    } else if (this.questObjectiveType === QuestStepObjectiveType.markerFind) {
      if (!this.markerFindEditEngine.isValid()) {
        return false;
      }
    }

    return this.startZoneEditEngine.isValid();
  }

  addNewHazardZone(zone?: QGraph): HazardZoneEditEngine {
    const onClickCallback = (zoneEngine: GenericZoneEditEngine) => {
      if (this.isEditingAnything()) {
        return;
      }

      this.clickHazardZoneBtn(zoneEngine);
    };

    const newHazarZone = new HazardZoneEditEngine(this.map, this.mapControlPanelActions$.asObservable(), this.hazardZonesCluster, zone, onClickCallback);

    this.hazardZonesCluster.instances.push(newHazarZone);

    //A little memory leak, because the subscription will linger after the zone was removed
    newHazarZone
      .mapControlPanelRequest$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.mapControlVisibility$.next(value);
      });

    newHazarZone
      .stopEdit$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.currentEditEngine = undefined;
        this.editMode = null;
      });

    newHazarZone
      .removeSelf$()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {
        const index = this.hazardZonesCluster.instances.indexOf(newHazarZone);
        this.removeHazardZone(index);
      });

    return newHazarZone;
  }

  removeHazardZone(index: number): void {
    this.hazardZonesCluster.instances.splice(index, 1)[0].clear();
    this.stopCurrentEdit();
  }

  clickStartZoneBtn(): void {
    const editModeWas = this.editMode;
    this.stopCurrentEdit();
    //if clicked on the same button, we just deactivate current edit mode
    if (editModeWas === 'startZone') {
      return;
    }

    this.currentEditEngine = this.startZoneEditEngine;
    this.currentEditEngine.startEditing();
    this.editMode = 'startZone';
  }

  clickHazardZoneBtn(zoneEngine: HazardZoneEditEngine): void {
    const sameZone = this.currentEditEngine === zoneEngine;
    this.stopCurrentEdit();

    if (sameZone) {
      return;
    }

    this.currentEditEngine = zoneEngine;
    this.currentEditEngine.startEditing();
    this.editMode = 'hazardZone';
  }

  clickStepDurationButton(): void {
    this.isTimerVisible = !this.isTimerVisible;
  }

  clickMarkerReachBtn(): void {
    const editModeWas = this.editMode;
    this.stopCurrentEdit();
    //if clicked on the same button, we just deactivate current edit mode
    if (editModeWas === 'reachMarker') {
      return;
    }

    this.currentEditEngine = this.markerReachEditEngine;
    this.currentEditEngine.startEditing();
    this.editMode = 'reachMarker';
  }

  clickMarkerFindBtn(): void {
    const editModeWas = this.editMode;
    this.stopCurrentEdit();
    //if clicked on the same button, we just deactivate current edit mode
    if (editModeWas === 'findMarker') {
      return;
    }

    this.currentEditEngine = this.markerFindEditEngine;
    this.currentEditEngine.startEditing();
    this.editMode = 'findMarker';
  }

  stopCurrentEdit(): void {
    if (this.currentEditEngine) {
      this.currentEditEngine.stopEditing();
      this.currentEditEngine = undefined;
    }

    this.editMode = null;
  }

  isEditingAnything(): boolean {
    if (this.hazardZonesCluster?.instances) {
      for (const hz of this.hazardZonesCluster.instances) {
        if (hz.editing) {
          return true;
        }
      }
    }

    return this.startZoneEditEngine?.editing || this.markerFindEditEngine?.editing || this.markerReachEditEngine?.editing;
  }

  goToMyLocation(): void {
    if (this.userGeo) {
      this.map.setCenter([this.userGeo.lng, this.userGeo.lat]);
    } else {
      navigator.geolocation.getCurrentPosition((value) => {
        this.userGeo = { lng: value.coords.longitude, lat: value.coords.latitude };
        this.map.setCenter([this.userGeo.lng, this.userGeo.lat]);
      });
    }
  }
  metersToPretty = metersToPretty;

  private storeLastLocation(coords: GeoCoords): void {
    localStorage.setItem('lastLocation', JSON.stringify(coords));
  }

  private getLastLocation(): GeoCoords | null {
    const ll = localStorage.getItem('lastLocation');
    return ll ? JSON.parse(ll) : null;
  }

  ngOnDestroy(): void {
    this.mapControlPanelActions$.complete();
    this.mapControlVisibility$.complete();
    this.destroy$.next();
    this.destroy$.complete();

    this.startZoneEditEngine?.destroy();
    this.markerReachEditEngine?.destroy();
    this.markerFindEditEngine?.destroy();
    this.hazardZonesCluster.instances.forEach((value) => {
      value.destroy();
    });
  }
}
