import {MapControlPanelRequestBody, MapEditEngine} from './map-edit-engine.interface';
// @ts-ignore
import * as mapboxgl from 'mapbox-gl';
import {NEVER, Observable, of, Subject, takeUntil} from 'rxjs';
import {MapboxSpecificService} from '../../services/mapbox-specific.service';
import * as turf from '@turf/turf';
import {FormControl} from '@angular/forms';
import {GeoCoords, QGraphVertexWithCircle} from '../../interfaces/geo-and-movement.interfaces';
import {randomCoordsInRadius} from '../../functions/geo.functions';
import {drawMarkerOnMap} from '../../functions/map-general.functions';

export class MarkerFindEditEngine implements MapEditEngine{

  editing = false;
  marker: mapboxgl.Marker;
  markerToFind: QGraphVertexWithCircle;
  coords?: GeoCoords;
  currentAddress?: string = '...';
  radiusControl = new FormControl<number>(70);

  private edit$ = new Subject<void>();
  private stopEditSubj$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor(private map: mapboxgl.Map, private mapboxSpecificService: MapboxSpecificService, marker?: QGraphVertexWithCircle) {
    if (marker) {
      this.markerToFind = marker;

      this.radiusControl.setValue(this.markerToFind.data.radius);

      this.map.on('load', () => {
        setTimeout(() => {
          this.marker = this.drawFindMarker(marker)
        }, 100);
      });

      this.mapboxSpecificService.reverseGeocoding(this.markerToFind.coords).pipe(takeUntil(this.destroy$)).subscribe(value => {
        this.currentAddress = value.features[0].place_name;
      });
    }

    this.radiusControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (!this.markerToFind) {
        return;
      }

      this.markerToFind.data.radius = value;

      const markerCenter = turf.point([this.markerToFind.coords.lng, this.markerToFind.coords.lat]);
      const taskCircleCenter = turf.point([this.markerToFind.data.circleCenter.lng, this.markerToFind.data.circleCenter.lat]);

      const dist = turf.distance(markerCenter, taskCircleCenter, {units: 'meters'});

      //if the marker is outside the circle, setting center back to the marker
      if (dist > this.markerToFind.data.radius) {
        this.markerToFind.data.circleCenter = this.markerToFind.coords;
      }

      this.drawCircle(this.markerToFind);
    });

    this.initDragListener();
  }

  postInit() {
  }

  removeSelf$(): Observable<void> {
    return NEVER;
  }

  clickOnMap(clickCoords: GeoCoords): void {
    if (!this.editing) {
      return;
    }

    let radius = 70;
    if (this.markerToFind) {
      radius = this.markerToFind.data.radius;
    }

    this.clear();

    this.markerToFind = {
      coords: clickCoords,
      data: {
        radius,
        circleCenter: randomCoordsInRadius(clickCoords, radius * 0.95)
      }
    }

    this.marker = this.drawFindMarker(this.markerToFind);

    this.mapboxSpecificService.reverseGeocoding(clickCoords).pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.currentAddress = value.features[0].place_name;
    });
    this.edit$.next();

    this.stopEditing();
  }
  hoverOnMap(coords: GeoCoords) {}

  clear(): void {
    if (this.markerToFind) {
      this.removeMarkerToFind();
    }

    this.edit$.next();

    (this.map.getSource('task-zone') as mapboxgl.GeoJSONSource).setData(turf.featureCollection([]));
    (this.map.getSource('task-zone-outline') as mapboxgl.GeoJSONSource).setData(turf.featureCollection([]));
  }

  startEditing(): void {
    this.editing = true;
  }

  stopEditing(): void {
    this.editing = false;
    this.stopEditSubj$.next();
  }

  getData(): any {
    return {
      marker: this.markerToFind,
      coords: this.coords,
      name: this.currentAddress
    }
  }

  externalRedraw(): void {
    this.drawCircle(this.markerToFind);
  }

  onEdit$(): Observable<void> {
    return this.edit$.asObservable();
  }

  private removeMarkerToFind(): void {
    this.marker.remove();
    this.marker = undefined;
    this.markerToFind = undefined;
    this.coords = undefined;
    this.currentAddress = '...';
  }

  private drawFindMarker(zoneData: QGraphVertexWithCircle): mapboxgl.Marker {
    this.coords = zoneData.coords;

    this.drawCircle(zoneData);

    return drawMarkerOnMap(zoneData.coords, this.map, '../../../../assets/map-icons/active-marker-1.svg');
  }

  protected drawCircle(zoneData: QGraphVertexWithCircle): void {
    const circle = turf.circle([zoneData.data.circleCenter.lng, zoneData.data.circleCenter.lat],
      zoneData.data.radius, {steps: 60, units: 'meters'});

    (this.map.getSource('task-zone') as mapboxgl.GeoJSONSource).setData(circle);
    (this.map.getSource('task-zone-outline') as mapboxgl.GeoJSONSource).setData(circle);
  }


  mapControlPanelRequest$(): Observable<MapControlPanelRequestBody> {
    return of<MapControlPanelRequestBody>({state: 'hide'});
  }

  isValid(): boolean {
    return !!this.coords;
  }

  nonEditClickOnMap(clickCoords: GeoCoords): void {
  }

  stopEdit$(): Observable<void> {
    return this.stopEditSubj$.asObservable();
  }

  destroy(): void {
    this.stopEditSubj$.complete();

    this.edit$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected initDragListener(): void {
    let inZone = false;
    let mousedown = false;

    const onMouseEnter = () => {
      if (!this.editing) {
        return;
      }

      this.map.getCanvasContainer().style.cursor = !mousedown ? 'grabbing' : 'move';
      inZone = true;
    }

    this.map.on('mouseenter', 'task-zone', onMouseEnter);

    const onMouseLeave = () => {
      this.map.getCanvasContainer().style.cursor = '';
      inZone = false;
    };

    this.map.on('mouseleave', 'task-zone', onMouseLeave);

    let prevLngLat: GeoCoords;

    const onDrag = (ev: any) => {
      if (!prevLngLat) {
        prevLngLat = ev.lngLat;
        return;
      }
      // console.log('mousemove', ev.lngLat);
      const newCoords: GeoCoords = {
        lng: this.markerToFind.data.circleCenter.lng + (ev.lngLat.lng - prevLngLat.lng),
        lat: this.markerToFind.data.circleCenter.lat + (ev.lngLat.lat - prevLngLat.lat)
      }

      const markerPoint = turf.point([this.markerToFind.coords.lng, this.markerToFind.coords.lat]);
      const newCoordsPoint = turf.point([newCoords.lng, newCoords.lat]);

      if (turf.distance(markerPoint, newCoordsPoint, {units: 'meters'}) > this.markerToFind.data.radius) {
        onMouseUp();
        return;
      }

      this.markerToFind.data.circleCenter = newCoords;

      this.drawCircle(this.markerToFind);
      prevLngLat = ev.lngLat;
    };

    let onMouseDownFired = false;

    const onMouseUp = () => {
      onMouseDownFired = true;
      prevLngLat = undefined;
      mousedown = false;
      this.map.off('mousemove', onDrag);

      if (inZone) {
        this.map.getCanvasContainer().style.cursor = 'move';
      } else {
        this.map.getCanvasContainer().style.cursor = '';
      }
    };

    const onMouseDown = (ev) => {
      if (!this.editing) {
        return;
      }

      ev.preventDefault();

      onMouseDownFired = false;

      this.map.getCanvasContainer().style.cursor = 'grabbing';
      mousedown = true;

      this.map.on('mousemove', onDrag);

      if (!onMouseDownFired) {
        this.map.once('mouseup', onMouseUp);
      }
    };

    this.map.on('mousedown', 'task-zone', onMouseDown);

    this.destroy$.subscribe(() => {
      this.map.off('mouseenter', onMouseEnter);
      this.map.off('mouseleave', onMouseLeave);
      this.map.off('mousedown', onMouseDown);
    })
  }
}
